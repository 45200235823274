import React, { useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';

import {
  ALIGN,
  BUTTON_STYLE,
  COLOR,
  MARGIN,
  PADDING,
  POSITION
} from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { FeatureTiles } from '@latitude/feature-tiles';
import { ImportantInformation } from '@latitude/important-information';
import { BrandedCallout } from '@latitude/callout';
import { Tel } from '@latitude/tel';
import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Layout from '../../components/layout';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { FeaturesSlider } from '@latitude/features-slider';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import PageData from '../../data/pages/calculators-and-tools/personal-loan-repayment-calculator.json';
import footerData from '../../data/json/footer.json';
import PersonalLoanCalculator from '@/components/LoanCalculator/PersonalLoanCalculator1';
import withStickyState from '../../hoc/withStickyState';
import Button from '@/components/Button/Button';
import { SOFT_QUOTE_LOANS_URL } from '../../utils/softQuoteUtil';
import HowToApplySection from '@/components/HowToApplySection/HowToApplySection1';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';

import heroImage from '@/images/hero-personal-loan-calculator.webp';
import { PageContext } from '../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const PLCalculatorPage = props => {
  const featureSliderItems = [
    {
      icon: 'icon-snap-fingers-branded',
      title: 'Un-complicated loans',
      text:
        'Get an indicative Quote in 2 minutes, Apply in under 7 minutes. Once approved and you accept your contract, funds in your account in less than 24 hours.'
    },
    {
      icon: 'icon-slinky',
      title: 'Flexible options',
      text:
        'At Gem, you can choose to apply for a Fixed or Variable loan, along with an option to set up monthly, fortnightly or weekly repayments.'
    },
    {
      icon: 'icon-extras-branded',
      title: 'Redraw extra funds',
      text:
        "Get flexible access to funds when you need them, when you're ahead on repayments with a Variable Rate Personal Loan."
    }
  ];

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [
    {
      heading: 'Why choose a Gem Loan?',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */
  return (
    <Layout
      location={props.location}
      noMetaKeywords
      customFooter={footerData}
      hasStickyNav
    >
      <main className="navigation-spacer navigation-spacer--hasStickyNav personal-loan-calculator-page">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Metadata
            title={PageData.title}
            description={PageData.description}
            canonical={PageData.path}
          />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title={
                <>
                  Personal Loans
                  <br />
                  Repayment Calculator
                </>
              }
              subTitle="Get an idea of how much your repayments could be for a Gem Personal Loan."
            />
          )}
          <div className="d-lg-none d-xl-none">
            <StickyCta
              href={SOFT_QUOTE_LOANS_URL}
              trackId="sticky-cta-get-my-rate"
              text="Get rate estimate"
              onClick={() => {
                // force link to open in the same tab
                if (typeof window !== 'undefined')
                  window.location = SOFT_QUOTE_LOANS_URL;
              }}
            />
          </div>
          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:10;"
          >
            <StickyNavigation
              items={PageData.nav}
              phoneNumber={PageData.content.phoneNumber}
              isSticky={props.isNavSticky}
              ctaHref={SOFT_QUOTE_LOANS_URL}
              ctaText="Get rate estimate"
              onStickyNavStateChange={props.handleStickyNavStateChange}
              offsetElem="[data-sticky-navigation-offset]"
              trackId="sticky-nav-get-my-rate"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                label: 'Get rate estimate',
                location: 'Sticky Navigation'
              }}
              trackProductId={['PLNZLF-WEB']}
            />
          </div>
          <Box
            id={PageData.nav[0].anchor}
            backgroundColor={COLOR.BLUE_SKY}
            position={POSITION.RELATIVE}
          >
            <Box padding={`${PADDING.P48} 15px 0 15px`}>
              <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER}>
                Calculate your repayments
              </Heading4>
            </Box>
            <Box isResponsive margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}>
              <PersonalLoanCalculator
                applyUrl={SOFT_QUOTE_LOANS_URL}
                applyCTATrackProductId={['PLNZLF-WEB']}
              />
            </Box>
          </Box>
          {state?.featureSliderData?.[0] && (
            <FeaturesSlider
              backgroundColor={COLOR.GREY6}
              heading={state.featureSliderData[0].heading}
              subheading={state.featureSliderData[0].description}
              data={state.featureSliderData[0].featureCards}
              className="why-choose pt-4 pt-md-5"
            />
          )}
          <HorizontalRule />

          <Box id={PageData.nav[1].anchor}>
            <BrandedCallout
              moreVerticalPadding
              hasConfettiBackground
              confettiBackground={PageData.content.callOut.bgImage}
              confettiBackgroundColor={COLOR.WHITE}
              confettiBackgroundRepeat="repeat"
              confettiBackgroundPos="center 35%"
              heading={PageData.content.callOut.title}
              line1={ReactHTMLParser(PageData.content.callOut.content)}
              cta={
                <Box>
                  <Button
                    secondary
                    href={PageData.content.callOut.ctaUrl}
                    trackId="callout-find-out-more"
                    trackEventData={{
                      category: 'button-link',
                      action: 'internal-link'
                    }}
                    trackProductId={['PLNZLF-WEB']}
                  >
                    {PageData.content.callOut.ctaLabel}
                  </Button>
                </Box>
              }
            />
          </Box>

          <HowToApplySection />
          <FeatureTiles
            id={PageData.nav[3].anchor}
            heading={PageData.content.calcTools.title}
            tiles={PageData.content.calcTools.tiles}
          />
          <BrandedCallout
            line1={
              <React.Fragment>
                <strong>
                  Call our loan specialists on{' '}
                  <Tel no="0800 422 898" color={COLOR.WHITE} />
                </strong>
              </React.Fragment>
            }
            line2={
              <React.Fragment>
                <span>Mon to Fri 9:00am - 6:30pm.</span>
              </React.Fragment>
            }
            cta={
              <Box width="210px">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={SOFT_QUOTE_LOANS_URL}
                  trackId="callout-get-my-rate"
                  className="w-auto"
                  target="_self"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: "We're here to help"
                  }}
                  trackProductId={['PLNZLF-WEB']}
                  style={{ marginTop: 24 }}
                >
                  Get rate estimate
                </Link>
              </Box>
            }
          />
          <ImportantInformation
            data={require('../../data/pages/calculators-and-tools/personal-loan-repayment-calculator.json')}
            sectionOneColummClasses="col-10 offset-1"
          />
        </Box>
      </main>
    </Layout>
  );
};

export default withStickyState(PLCalculatorPage);
